
#re{
     width: 60%;
     height: auto;
}
#spo{
     display: flex;
     justify-content: center;
}
@media(max-width: 600px){
     #re{
          width: 100%;
          height: auto;
     }
   }