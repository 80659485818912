.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2em;
  margin-top: -2rem;
}

.contact-form {
  flex: 1;
  margin-right: 2em;
  /* background-color: #55a6e9; */
  border-radius: 12px;
}
text{
  font-size: 0.9rem;
}

.contact-form h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5em;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form textarea {
  height: 10em;
}

.contact-form button {
  background-color: #4caf50;
  color: #fff;
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3em;
  }
  
  .contact-form {
    flex: 1;
    margin-right: 32em;
  }
  
  .contact-form h2 {
    font-size: 1.5em;
    margin-bottom: 3em;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 0.5em;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    height: 10em;
  }
  
  .contact-form button {
    background-color: #326fbe;
    color: #fff;
    border-radius: 10px;
    border: none;
  }

  .peppa {
    flex: 1;
    margin-left: -27em;
  }
  
  .peppa h2 {
    font-size: 1.5em;
    margin-bottom: 1em;
  }
  
  .gm-style-mtc {
    display: none;
  }
  
  .gm-style-cc {
    display: none;
  }
  
  .gm-style-pbc {
    display: none;
  }
  
  .gm-style-iw-c {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  @media(max-width: 600px){
    .peppa {
      flex: 1;
      margin-left: -7em;
      width: 450;
    }
    .peppa h2 {
      font-size: 1.5em;
      margin-bottom: 1em;
      margin-left: 10rem;
    }
  }