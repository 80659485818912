/* @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');
#hms{
    height: 50rem;
    display: flex;
    /* background-color: #F4F5F7; */
    /* height: 19rem; */
    /* margin-top: 10rem; */
    justify-content:baseline;
  }
  
#ar{
  /* width: 6%;
  height: 10%; */
  margin-left: 10rem;
  margin-top: 43rem;
  color: #236ac7;
  visibility: hidden;
}

  #hmsc{
    padding-right: 3rem;
    z-index: 1;
    align-self: center;
    margin-right: 15rem;
    width: 28rem;
    margin-top: 5.5rem;
    gap: 5px;
    color: rgb(255, 255, 255);
}
#tab{
    background-color: rgb(68, 87, 255);
    border-radius: 1rem;
    /* width: 14rem; */
    height: 24rem;
    display: flex;
    justify-content: center;
    margin-left: 3rem;
    box-sizing: border-box;
    /* font-family: 'Nunito', serif;
    font-style: italic; */
    position: relative;
    box-shadow: inset;
    box-shadow: 15px 15px 11px rgb(160, 160, 160);
}
#demo {
    /* width: 300px;
    height: 300px; */
    animation: blinking 1s infinite;
    justify-content: center;
    font-size: 3rem;
    text-align: center;
    
  }
.bb{
    display: block;
}
@keyframes blinking {
    0% {
      color: #ffffff;
      /* border: 5px solid #871924; */
    }

    50% {
      color: #2ae1e7;
      /* border: 5px solid #126620; */
    }
    100% {
      color: #222291;
      /* border: 5px solid #6565f2; */
    }
  }

#dim{
  background-image: url('/src/assets/bg.jpg');
  position:relative;
  min-width: 100%;
  min-height: 100%;
  height: 50rem;
  background-size:cover;
  background-position: center;
}

#coabs{
  display: flex;
  justify-content: center;
  height: 31%;
  
}

#abs{
  /* display: flex; */
  /* position:relative;
  gap: 5px;
  margin-left: 17rem; */
  /* min-width: 60rem;
  max-width: 60rem;
  min-height: 40rem; */
  /* border:2px solid; */
  background-color: rgb(255, 255, 255);
  border-radius: 1rem;
  /* box-shadow: 15px 15px 12px rgb(168, 168, 168); */
  width: 60rem;
  text-align: center;
}
h1{
  color: #035394;
}

#car{
  display: flex;
  justify-content: center;
  /* width: 100%;
  position: relative; */
  /* min-width: 100%;
  min-height: 100%; */
  /* background-size:cover;
  background-position: center; */
  /* align-items: center;  */
  margin-top: 8rem;
  height: 6rem;
  
}

#we{
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  max-width: 1300px;
  width: 100%;
  font-size: 1.8rem;
  /* font-weight: bold; */
  /* background-color: rgb(231, 229, 229); */
  color:#035394;
  height: 4.5rem;
  border-radius: 10px;
  /* border:2px solid aqua; */
}

#congif{
  visibility: visible;
  width: 36%;
  /* width: 50%;
  margin-left: 25rem; */
}

/* #itemgif{
} */

#log{
  width: 75%;
  height: auto;
  padding-top: 45%;
  margin-left: 100%;
}

#btnhs{
  margin-left: 5rem;
  line-height: 40px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.5s;
	position: relative;
  border: none;
  background-color: white;
  color: #222291;
  border: 2px solid aqua;
}

#btnhs::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  z-index: 10;
	background-color: rgba(64, 98, 250, 0.1);
	transition: all 0.3s;
  border-radius: 16px;
}

#btnhs:hover::before {
	opacity: 0 ;
	transform: scale(0.5,0.5);
}
#btnhs::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.3s;
	border: 3px solid rgba(8, 52, 245, 0.5);
	transform: scale(1.2,1.2);
  border-radius: 16px;
}
#btnhs:hover::after {
	opacity: 1;
	transform: scale(1,1);
}

@media(max-width: 600px){
  #ar{
    /* width: 6%;
    height: 10%; */
    margin-left: 10rem;
    margin-top: 43rem;
    color: #236ac7;
    visibility: hidden;
  }
  #coabs{
      height: 80rem;
  }
  #we{
    height: 8.5rem;
  }
  #car{
    margin-top: 1rem;
    height: 11rem;
  }
  #congif{
    visibility: visible;
    /* width: 650px; */
    
  }
  #log{
    width: 500px;
    height: auto;
    padding-top: 10rem;
    margin-left: -46%;
  }
  #tab{
    background-color: rgb(68, 87, 255);
    border-radius: 1rem;
    width: 14rem;
    height: 24rem;
    display: flex;
    justify-content: center;
    margin-left: 1.8rem;
    box-sizing: border-box;
    /* font-family: 'Nunito', serif;
    font-style: italic; */
    position: relative;
    box-shadow: inset;
    box-shadow: 15px 15px 11px rgb(160, 160, 160);
}
  #demo {
    /* width: 300px;
    height: 300px; */
    animation: blinking 1s infinite;
    justify-content: center;
    font-size: 2rem;
    text-align: center; 
  }
  #btnhs{
    margin-right: 4rem;
  }
  #hmsc{
    padding-right: 3rem;
    z-index: 1;
    align-self: center;
    margin-right: 15rem;
    width: 28rem;
    margin-top: 7rem;
    gap: 5px;
    color: rgb(255, 255, 255);
}
}