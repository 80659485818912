* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Outfit', serif;
    font-size: 1.2rem; text-decoration: none;
    color: inherit;
}

a, .dropbtn {
    display: inline-block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

a:hover, .dropdown:hover .dropbtn {
    cursor: pointer;
    visibility: visible;
  }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ece9e9;
    min-width: 260px;max-height: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    overflow-y: hidden;
    transition: max-height 0.2s ease-out;
  }
  .dropdown-content.open {
    max-height: 700px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #fff;
  }

  .dropdown-content a {
    color: blue;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {background-color: #72a8f0;}

  .dropdown:hover .dropdown-content {
    display: block;
  }

#contnav{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 5px;
    background-color:white;
    height: 70px;
    text-decoration: none;
    position:fixed;
    z-index: 10;
}

/* .contnav-scrolled{
    background-color: aqua;
} */

.item0{
    order: 5;
    flex-grow: 4;
    width: 5px;
    padding: 7px;
    height: 10px;
    color: blue;
    align-content: center;
    text-decoration: none;
    text-align: right; 
    visibility: visible;
}
.item1{
    order: 5;
    flex-grow: 4;
    width: 5px;
    padding: 7px;
    height: 10px;
    color: blue;
    align-content: center;
    text-decoration: none;
    text-align: center; 
}
.itemspec{
    order: 5;
    flex-grow: 4;
    width: 5px;
    padding: 7px;
    height: 10px;
    color: blue;
    align-content: center;
    text-decoration: none;
    text-align: center; 
}
.item2{
    order: 5;
    flex-grow: 4;
    text-decoration: none;
    text-align: right;
    visibility: visible;
}
.item3{
    order: 5;
    flex-grow: 4;
    text-decoration: none;
    text-align: center;
    visibility: visible;
}
/* .item2{
    order: 5;
    flex-grow: 4;
    text-decoration: none;
}
.item3{
    order: 5;
    flex-grow: 4;
    text-decoration: none;
} */
.it{
    color: inherit;
    color: rgb(49, 121, 255);
    text-decoration: none;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
}
.it:hover{
    color: rgb(224, 80, 243);
    letter-spacing: 1px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
}
#o{
    visibility:visible;
    margin-bottom:0.3rem;
    margin-right: 0.5rem;
    
}
/* .n{
    visibility: hidden;
} */
@media(max-width: 600px){
    /* html{
        width: 40px;
    } */
    /* a, .dropbtn {
        display: inline-block;
        text-align: center;
        padding: 0px 16px;
        text-decoration: none;
      }
    .dropdown-content a {
        color: blue;
        padding: -0px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        border: solid 1px;
      } */
    .dropdown-content {
        display: none;
        position:absolute;
        background-color: #ece9e9;
        min-width: 26px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      }
    .item2{
        order: 5;
        flex-grow: 4;
        text-decoration: none;
        text-align: right;
        visibility: hidden;
    }
    .item3{
        order: 5;
        flex-grow: 4;
        text-decoration: none;
        text-align: center;
        visibility: hidden;
    }
    #contnav{
        display:flex;
        /* display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: baseline;
        gap: 5px;
        background-color:white;
        height: 70px;
        text-decoration: none;
        position:absolute; */
        height: 70px;
        z-index: 10;
    }
    .itemspec{
        order: 5;
        flex-grow: 4;
        width: 5px;
        padding: 7px;
        height: 10px;
        color: blue;
        align-content: center;
        text-decoration: none;
        text-align: center; 
        visibility: hidden;
    }
    .item1{
        order: 5;
        flex-grow: 4;
        width: 5px;
        padding: 7px;
        height: 10px;
        color: blue;
        align-content: center;
        text-decoration: none;
        text-align: left; 
        visibility: hidden;  
    }
    /* .n{
        visibility:hidden;
    } */
    #o{
        visibility: visible;
    }
    .item0{
        order: 5;
        flex-grow: 9;
        width: 5px;
        padding: 7px;
        height: 10px;
        color: blue;
        align-content: center;
        text-decoration: none;
        text-align: left; 
        visibility: hidden;
    }
}