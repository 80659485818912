/* #cf{
    flex-direction: row;
    display: flex;
    padding-top: 40rem;
    justify-content: center;
    background-color: #8ebef8;
}
#cfi{
    align-self: flex-end;
    height: 3.5rem;
    text-align: center;
    justify-content: center;
    width: 40rem;
} */
#ft{
    margin-top: 8%;
    position: relative;
    background-color: #236ac7;
    display: flex;
    justify-content: center;
}
.bga{
    background-color: #ffffff;
}

#gg{
    margin-bottom: 0.01%;
    text-align: center;
    color: #ffffff;
}
#ac{
    color: #ffffff;
    text-decoration: none;
}
.it2{
    visibility: hidden;
}
.l{
    color: #ffffff;
}

@media(max-width: 600px){
    .l{
        color: #ffffff;
    }
    .it2{
        visibility:visible;
    }
}
.simone{
    font-size: 63%;
}
.benefici{
    font-size: 53%;
    font-style: italic;
}