/* .wasd{
    margin-top: 30rem;
} */
/* body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.5;
  } */
  #privacy-policy {
    margin: auto;
    width: 100%;
    padding: 20px;
    font-family: Arial, sans-serif;
    }
    
    #privacy-policy-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    }
    
    #privacy-policy-text {
    margin-bottom: 30px;
    font-weight: bold;
    }
    
    #privacy-policy-disclaimer {
    text-align: center;
    margin-top: 30px;
    font-size: 20px;
    color: #ffffff;
    background-color: #236ac7;
    border-radius: 12px;
    }
    
    #privacy-policy-link {
    color: #ffffff;
    text-decoration: none;
    }
    
    #privacy-policy-link:hover {
    color: #23527c;
    text-decoration: underline;
    }
    
    #privacy-policy-highlight {
    background-color: #fff9a6;
    padding: 5px;
    font-weight: bold;
    border: 1px solid #ccc;
    }
    
    #privacy-policy-avviso {
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    color: #f00;
    }
    #fonst{
        font-size: 100%;
    }
  