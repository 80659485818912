/* ResponsiveCards.css */

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
    
}

.card {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 0%;
    margin-bottom: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    text-align: center;
    transition: width 0.3s ease;
    user-select: none;
}

.card img {
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    /* border-color: #72b6d3;
    border-style:solid; */
}

.card button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-top: auto; /* Push button to the bottom */
}

.buttonHref {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px 0px;
    cursor: pointer;
    margin-top: auto; /* Push button to the bottom */
}

/* Media Queries for responsive design */
@media screen and (max-width: 1200px) {
    .card {
        width: 18%;
    }
}

@media screen and (max-width: 992px) {
    .card {
        width: 22%;
    }
}

@media screen and (max-width: 768px) {
    .card {
        width: 28%;
    }
}

@media screen and (max-width: 576px) {
    .card {
        width: 40%;
    }
}

@media screen and (max-width: 480px) {
    .card {
        width: 90%;
        margin: 10px auto;
    }
}
