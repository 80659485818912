.popup-content {
    justify-content: center;
    z-index: 1;
    position: absolute;
    top: 113%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #236ac7;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 33%;
    height: auto;
    border-radius: 10px;

  }

  .popup-enter {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  
  .popup-enter-active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .popup-exit {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  
  .popup-exit-active {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
    transition: opacity 300ms, transform 300ms;
  }




.s{
    background-color: #bfe6f8;
    width: 100%;
    height: auto;
    border-radius: 10px;

}

.d{
    width: 90%;
    height: auto;
    padding-left: 2rem;
}
.y{
    font-size: 60%;
    border: none;
}

#carf{
    display: flex;
    justify-content: center;
    /* width: 100%;
    position: relative; */
    /* min-width: 100%;
    min-height: 100%; */
    /* background-size:cover;
    background-position: center; */
    align-items: center;
    margin-top: 8rem;
}

#descrserv{
    display: flex;
    width: 100%;
    height:auto;
    justify-content:baseline;
}
#descrserv2{
    display: flex;
    width: 100%;
    height:auto;
    justify-content:center;
}
#descrserv3{
    display: flex;
    width: 100%;
    height:auto;
    justify-content:center;
    font-size: 60%;
}
#po{
    color: rgb(0, 0, 0);
    width: 100%;
    text-align: center;
    /* padding: 10px; */
}

#alb{
    height: 4rem;
    margin-top: -2rem;
    border: none;
    margin-bottom: 3rem;
    justify-content: baseline;
}
.container1{
    max-width: 1300px;
    width: 70%;
    margin-bottom: 10rem;
    padding: 10px 20px;
}

#bssl{
    border: none;
    border-radius: 12px;
    margin-bottom: 0rem;
    background-color: #035394;
    color: white;
    padding: 2%;
}
#bssl1{
    border: none;
    border-radius: 12px;
    margin-bottom: 0rem;
    background-color: #035394;
    color: white;
    padding: 0.3%;
    /* font-size: 77%; */
}
#bssl2{
    border: none;
    border-radius: 14px;
    margin-bottom: 0rem;
    background-color: #ffffff;
    color: rgb(21, 88, 233);
    padding: 1%;
    margin-left: 9.7rem;
    font-weight: bold;
    /* font-size: 77%; */
}

#cardimg{
    width: 100%;
    height: auto;
    align-self: center;
}

.container{
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0px 20px;
}
.container1{
    max-width: 90%;
    width: 180rem;
    margin: auto;
    padding: 0px 20px;
}
.section{
    min-height: 100vh;
    display: flex;
    flex-direction:column;
    align-items: center;
}
#tit{
    font-size: 220%;
    line-height: 160%;
    text-align: center;
    margin-bottom: 3%;
    border-radius: 10px;
    overflow: hidden;
    /* background: linear-gradient(to right, rgb(228, 227, 227) 20%, rgb(228, 227, 227) 40%, #bfe6f8 50%, #a9d5f8 55%, rgb(228, 227, 227) 70%, rgb(228, 227, 227) 100%);
    background-size: 200% auto;
    animation: shine 3s linear infinite; */
    color: #035394;
    
}
#tit3{
    font-size: 220%;
    line-height: 160%;
    text-align: center;
    margin-bottom: 3%;
    border-radius: 10px;
    overflow: hidden;
    /* background: linear-gradient(to right, rgb(228, 227, 227) 20%, rgb(228, 227, 227) 40%, #bfe6f8 50%, #a9d5f8 55%, rgb(228, 227, 227) 70%, rgb(228, 227, 227) 100%);
    background-size: 200% auto;
    animation: shine 3s linear infinite; */
    color: #035394;
    
}
#tit2{
    font-size: 220%;
    line-height: 160%;
    text-align: center;
    margin-bottom: 1%;
    border-radius: 10px;
    overflow: hidden;
    /* background: linear-gradient(to right, rgb(228, 227, 227) 20%, rgb(228, 227, 227) 40%, #bfe6f8 50%, #a9d5f8 55%, rgb(228, 227, 227) 70%, rgb(228, 227, 227) 100%);
    background-size: 200% auto;
    animation: shine 3s linear infinite; */
    color: #035394;
    
}

.corsivo{
    font-style: italic;
    font-size: 65%;
}

.sottolineato{
 text-decoration: underline;
 /* color: #ec6521; */
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}
.cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 6%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
}
.cardsw{
    display: flex;
    flex-wrap: wrap;
    margin-top: 6%;
    justify-content:space-around;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-left: 1%;
}

.cards1{
    display: flex;
    flex-wrap:wrap;
    justify-content:center;
    margin-top: 6.5rem;
    
}
.cards2{
    color: #035394;
    display: flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin-top: 6.5rem;
    
}
.card{
    flex: 0 0 calc(20% - 20px);
    max-width: calc(20% - 20px);
    max-height: calc(20% - 20px);
    width: 100%;
    text-align: center;
    justify-content:baseline;
    background: rgb(255, 255, 255);
    border-radius: 4%;
    padding: 1%;
    margin-bottom: 12.5%;
    transition: .3s;
    border: 2px solid transparent;
    /* box-shadow: 15px 15px 12px  rgb(168, 168, 168); */
    
}
.cardBlog{
    flex: 0 0 calc(30% - 20px);
    max-width: calc(30% - 20px);
    max-height: calc(20% - 20px);
    width: 100%;
    text-align: center;
    justify-content:baseline;
    background: rgb(255, 255, 255);
    border-radius: 4%;
    padding: 1%;
    margin-bottom: 1%;
    margin-top: -2%;
    transition: .3s;
    border: 2px solid transparent;
    background-color: rgb(238, 238, 238);
    /* box-shadow: 15px 15px 12px  rgb(168, 168, 168); */
    
}
.cardBlog1{
    flex: 0 0 calc(30% - 20px);
    max-width: calc(30% - 20px);
    max-height: calc(20% - 20px);
    width: 100%;
    text-align: center;
    justify-content:space-around;
    background: rgb(255, 255, 255);
    border-radius: 4%;
    padding: 1%;
    margin-bottom: 1%;
    margin-top: -2%;
    transition: .3s;
    border: 2px solid transparent;
    background-color: rgb(238, 238, 238);
    /* box-shadow: 15px 15px 12px  rgb(168, 168, 168); */
    
}
.card1{
    flex: 0 0 calc(25% - 20px);
    max-width: calc(25% - 20px);
    width: 100%;
    text-align: center;
    justify-content:baseline;
    background: rgb(231, 230, 230);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 1.5%;
    transition: .3s;
    /* border: 2px solid transparent; */
    /* box-shadow: 15px 15px 12px  rgb(168, 168, 168); */
    
}



.card:hover{
    border-color: rgb(68, 68, 255);
}
.cardBlog:hover{
    border-color: rgb(68, 68, 255);
}
#tecard{
    color: rgb(0, 132, 255);
    font-size: 0.9rem;
    font: bold;
}
#tecard2{
    font-size: 20px;
    color: rgb(0, 132, 255);
    font-weight: bold;
}
#pcol{
    color: rgb(4, 91, 141);
    font-weight: bold;
    font-size: 2rem;
    /* animation: blinking 1s infinite; */
    border: 2px solid rgb(38, 185, 243);
}
#pcol1{
    color: rgb(4, 91, 141);
    /* font-weight: bold; */
    /* font-size: 1000px; */
    /* animation: blinking 1s infinite; */
    border: 2px solid rgb(38, 185, 243);
}
#pcol2{
    color: rgb(0, 132, 255);
    /* font-weight: bold; */
    font-size:15px;
    /* animation: blinking 1s infinite; */
    border: 4px solid rgb(0, 132, 255);
    padding: 5%;
}
li{
    font-size: 0.6rem;
}


@media(max-width: 600px){
    #bssl2{
        border: none;
        border-radius: 12px;
        margin-bottom: 0rem;
        background-color: #ffffff;
        color: rgb(21, 88, 233);
        padding: 1%;
        margin-left: 5rem;
        font-weight: bold;
        /* font-size: 77%; */
    }
    .popup-content {
        z-index: 1;
        position: absolute;
        top: 85rem;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(38, 111, 247);
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    #tit{
        font-size: 36px;
        line-height: 60px;
        text-align: center;
        margin-bottom: 40px;
        border-radius: 10px;
        overflow: hidden;
        /* background: linear-gradient(to right, rgb(228, 227, 227) 20%, rgb(228, 227, 227) 40%, #bfe6f8 50%, #a9d5f8 55%, rgb(228, 227, 227) 70%, rgb(228, 227, 227) 100%); */
        /* background-size: 200% auto;
        animation: shine 3s linear infinite; */
        color: #035394;
        
    }
    #po{
        color: rgb(0, 0, 0);
        width: 100%;
        /* padding: 10px; */
    }
    .card{
        flex: 0 0 100%;
        max-width: 100%;
        border: 2px solid;
        border-color: rgb(68, 68, 255);
    }
}